<template>
  <UseTemplate>
    <div class="px-4">
      <FormFeedback :state="formState" @submit="onFormSubmitted" />
    </div>
  </UseTemplate>
  <UiDialog
    v-if="isDesktop"
    :open="open"
    @update:open="
      (is) => {
        if (formState) return;

        if (!is) {
          $emit('close');
        }
      }
    "
  >
    <UiDialogContent class="sm:max-w-[425px]">
      <UiDialogHeader>
        <UiDialogTitle>{{ title }}</UiDialogTitle>
        <UiDialogDescription>{{ description }}</UiDialogDescription>
      </UiDialogHeader>
      <GridForm />
      <UiDialogFooter>
        <UiDialogClose as-child class="mx-4">
          <UiButton variant="outline" class="w-full" :disabled="formState">
            Cancel
          </UiButton>
        </UiDialogClose>
      </UiDialogFooter>
    </UiDialogContent>
  </UiDialog>

  <UiDrawer
    v-else
    :open="open"
    @update:open="
      (is) => {
        if (formState) return;

        if (!is) {
          $emit('close');
        }
      }
    "
  >
    <UiDrawerContent>
      <UiDrawerHeader class="text-left">
        <UiDrawerTitle>{{ title }}</UiDrawerTitle>
        <UiDrawerDescription>{{ description }}</UiDrawerDescription>
      </UiDrawerHeader>
      <GridForm />
      <UiDrawerFooter class="pt-2">
        <UiDrawerClose as-child>
          <UiButton variant="outline" :disabled="formState"> Cancel </UiButton>
        </UiDrawerClose>
      </UiDrawerFooter>
    </UiDrawerContent>
  </UiDrawer>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { createReusableTemplate, useMediaQuery } from "@vueuse/core";
import { sleep } from "~/lib/util";
// import { uploadOBSLogs } from "~/lib/streamlabs-api";

defineProps<{ open?: boolean }>();

const emit = defineEmits(["close"]);

// Reuse `form` section
const [UseTemplate, GridForm] = createReusableTemplate();
const isDesktop = useMediaQuery("(min-width: 768px)");

const title = ref("Send Feedback");
const description = ref(
  "Help us improve our OBS Plugin by providing feedback.",
);

const $api = useApi();

const formState = ref<"busy" | "success">();
const onFormSubmitted = async (payload: Record<string, any>) => {
  if (formState.value) {
    return;
  }

  formState.value = "busy";

  // const logPath = await uploadOBSLogs();

  // payload.message += `\n\nLogs: ${logPath}`;

  await $api.fetch("/api/v5/obs-plugin/help/create-ticket", {
    method: "POST",
    body: {
      subject: "OBS Plugin: Feedback",
      description: payload.message,
      name: payload.name,
      email: payload.email,
    },
  });

  formState.value = "success";

  await sleep(1250);

  emit("close");

  await sleep(1000);

  formState.value = undefined;
};
</script>
