<template>
  <DialogOnboarding
    :open="!app.onboardingComplete"
    @cancel="app.onboardingComplete = true"
  />

  <DialogUltraRequired
    v-if="app.dialogs.ULTRA_REQUIRED"
    :open="true"
    v-bind="app.dialogs.ULTRA_REQUIRED"
    @cancel="app.dialogs.ULTRA_REQUIRED = false"
  />

  <DialogRestartRequired
    :open="app.dialogs.RESTART_OBS"
    @cancel="app.dialogs.RESTART_OBS = false"
  />

  <DialogLogin :open="!auth.isAuthenticated" />

  <DialogBetaRestricted :open="app.dialogs.BETA_RESTRICTED" />

  <DialogGoLive
    v-if="app.dialogs.GO_LIVE"
    :open="true"
    @cancel="app.dialogs.GO_LIVE = false"
  />

  <DialogPluginOutdated
    v-if="app.dialogs.PLUGIN_OUTDATED"
    :open="true"
    v-bind="app.dialogs.PLUGIN_OUTDATED"
  />

  <DialogFeedback
    :open="app.dialogs.FEEDBACK"
    @close="app.dialogs.FEEDBACK = false"
  />

  <template v-for="(item, i) in app.commonDialogs" :key="i">
    <UiAlertDialog :open="true">
      <UiAlertDialogContent>
        <UiAlertDialogHeader>
          <UiAlertDialogTitle>{{ item.title }}</UiAlertDialogTitle>
          <UiAlertDialogDescription
            v-sanitize-html="mdToHtml(item.description)"
            class="whitespace-pre-wrap pb-4"
          >
          </UiAlertDialogDescription>
        </UiAlertDialogHeader>
        <UiAlertDialogFooter>
          <UiAlertDialogCancel
            :disabled="item.busy"
            @click="() => item.cancelBtnFn()"
          >
            {{ item.cancelBtnText }}
          </UiAlertDialogCancel>
          <UiAlertDialogAction as-child @click="() => item.confirmBtnFn()">
            <Button :busy="item.busy">{{ item.confirmBtnText }}</Button>
          </UiAlertDialogAction>
        </UiAlertDialogFooter>
      </UiAlertDialogContent>
    </UiAlertDialog>
  </template>
</template>

<script setup lang="ts">
import { parse as mdToHtml } from "marked";

const auth = useAuthStore();
const app = useAppStore();
</script>
