import { z } from "zod";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { RstToPlatformType } from "~/stores/multistream.store";
import { toZodEnum } from "~/lib/forms";

export const createFileField = (
  options: Omit<WithOptional<FieldFile, "schema">, "type">,
) => {
  return {
    ...options,
    type: "file" as const,
    schema:
      options.schema ||
      z.union([
        z.string(),
        z
          .custom<File>(
            (file: File) =>
              !file?.type ||
              ["image/jpeg", "image/png", "image/gif"].includes(file.type),
            { message: "Invalid file type" },
          )
          .refine((file) => !file?.size || file.size <= 1024 * 1024 * 2, {
            message: "File size exceeds the limit",
          }),
      ]),
  };
};

export const twitchTagSchema = z
  .string()
  .trim()
  .regex(/^[A-Z0-9]+$/i, "Tags can only contain letters and numbers")
  .max(25, "Tags must be 25 characters or less");

export const useGoLiveForm = () => {
  const api = useApiStore();
  const multistream = useMultistreamStore();

  const ytInfo = api.golive.youtube.info;

  const ytDefaults = {
    privacy: ref<"public" | "private" | "unlisted">("public"),
    latency: ref("normal"),
    category: ref("20"),
  };

  const fields: Record<string, Record<string, Field>> = {
    shared: {
      title: {
        type: "text",
        name: "title",
        label: "Title",
        placeholder: "Enter a title",
        schema: z.string().min(1).max(140),
      },
      description: {
        type: "textarea",
        name: "description",
        label: "Description",
        placeholder: "Enter a description",
        schema: z.string().optional(),
      },
    },

    youtube: {
      title: {
        type: "text",
        name: "youtube_title",
        label: "Title",
        placeholder: "Enter a title",
        schema: z.string().min(1).max(140),
      },
      description: {
        type: "textarea",
        name: "youtube_description",
        label: "Description",
        placeholder: "Enter a description",
        schema: z.string().optional(),
      },
      scheduled_event: {
        type: "combo",
        name: "youtube_broadcast_id",
        label: "Event",
        placeholder: "Select an event",
        options: computed(() => {
          const options = [{ value: "0", label: "Create New Event" }];
          const scheduledStreams = ytInfo.data?.scheduled_streams || [];

          options.push(
            ...scheduledStreams.map(({ id, title }) => ({
              value: id,
              label: title,
            })),
          );

          return options;
        }),

        schema: computed(() => {
          const options = ["0"];
          const scheduledStreams = ytInfo.data?.scheduled_streams || [];

          options.push(...scheduledStreams.map(({ id }) => id));

          return toZodEnum(options)
            .transform((v) => (v === "0" ? undefined : v))
            .default("0");
        }),
      },

      visiblity: {
        type: "select",
        name: "youtube_privacy",
        label: "Privacy",
        placeholder: "Select a privacy option",
        options: [
          { value: "public", label: "Public" },
          { value: "private", label: "Private" },
          { value: "unlisted", label: "Unlisted" },
        ],
        schema: z
          .enum(["public", "private", "unlisted"])
          .default(ytDefaults.privacy.value),
      },

      category: {
        type: "select",
        name: "youtube_category",
        label: "Category",
        placeholder: "Select a category",
        options: computed(() => {
          if (!ytInfo.data?.categories) return [];

          return ytInfo.data.categories.map(({ id, title }) => ({
            value: id,
            label: title,
          }));
        }),

        schema: computed(() => {
          if (!ytInfo.data?.categories) return z.string().optional();

          return toZodEnum(ytInfo.data.categories.map(({ id }) => id)).default(
            ytDefaults.category.value,
          );
        }),
      },

      latency: {
        type: "select",
        name: "youtube_latency",
        label: "Stream Latency",
        placeholder: "Select a latency",
        options: computed(() => {
          if (!ytInfo.data?.settings) return [];

          return Object.entries(ytInfo.data.settings.latency).map(
            ([value, label]) => ({ value, label }),
          );
        }),
        schema: computed(() => {
          if (!ytInfo.data?.settings) {
            return z.string().optional();
          }

          return toZodEnum(Object.keys(ytInfo.data.settings.latency)).default(
            ytDefaults.latency.value,
          );
        }),
      },

      thumbnail: createFileField({
        name: "youtube_thumbnail",
        label: "Thumbnail",
        allowedTypes: ["image/jpeg", "image/png", "image/gif"],
        maxSize: 1024 * 1024 * 2,
      }),

      options: {
        label: "Options",
        type: "checkbox_group",
        fields: [
          {
            type: "checkbox",
            name: "youtube_auto_start",
            label: "Enable auto-start",
            schema: z.boolean().optional().default(true),
          },
          {
            type: "checkbox",
            name: "youtube_auto_stop",
            label: "Enable auto-stop",
            schema: z.boolean().optional().default(true),
          },
          {
            type: "checkbox",
            name: "youtube_dvr",
            label: "Enable DVR",
            schema: z.boolean().optional().default(true),
          },

          {
            type: "checkbox",
            name: "youtube_360_video",
            label: "360° Video",
            schema: z.boolean().optional().default(false),
          },

          {
            type: "checkbox",
            name: "youtube_for_kids",
            label: "Made for kids",
            schema: z.boolean().optional().default(false),
          },
        ],
      },
    },
    twitch: {
      title: {
        type: "text",
        name: "twitch_title",
        label: "Title",
        placeholder: "Enter a title",
        schema: z.string().min(1).max(140),
      },
      tags: {
        type: "twitch_tags",
        name: "twitch_tags",
        label: "Tags",
        schema: z.array(twitchTagSchema).max(10).default([]),
      },
      category: {
        type: "twitch_category",
        name: "twitch_category",
        label: "Category",
        schema: z.string().optional(),
      },
    },
    facebook: {
      title: {
        type: "text",
        name: "facebook_title",
        label: "Title",
        placeholder: "Enter a title",
        schema: z.string().min(1).max(140),
      },
      description: {
        type: "textarea",
        name: "facebook_description",
        label: "Description",
        placeholder: "Enter a description",
        schema: z.string().optional(),
      },
    },
    twitter: {
      title: {
        type: "text",
        name: "twitter_title",
        label: "Title",
        placeholder: "Enter a title",
        schema: z.string().min(1).max(140),
      },
    },
    trovo: {
      title: {
        type: "text",
        name: "trovo_title",
        label: "Title",
        placeholder: "Enter a title",
        schema: z.string().min(1).max(140),
      },
    },
  };

  const fieldsets = computed<undefined | Fieldset[]>(() => {
    if (multistream.enabledDestinations.length === 0) {
      return;
    }

    if (multistream.enabledDestinations.length > 1) {
      const fieldsets: Fieldset[] = [
        {
          label: "Shared Settings",
          fields: [
            fields.shared.title,
            fields.shared.description,
            //
          ],
        },
      ];

      if (
        multistream.enabledPlatforms.includes(RstToPlatformType.twitch_account)
      ) {
        const { title, description, ...other } = fields.twitch;
        fieldsets.push({
          label: "Twitch Settings",
          fields: Object.values(other),
        });
      }

      if (
        multistream.enabledPlatforms.includes(RstToPlatformType.youtube_account)
      ) {
        const { title, description, ...other } = fields.youtube;
        fieldsets.push({
          label: "YouTube Settings",
          fields: Object.values(other),
        });
      }

      return fieldsets;
    }

    const [destination] = multistream.enabledDestinations;

    if (destination.type !== "platform") {
      return;
    }

    switch (destination.platform_type) {
      case "twitch":
        return [
          {
            label: "Twitch Settings",
            fields: Object.values(fields.twitch),
          },
        ];

      case "youtube":
        return [
          {
            label: "YouTube Settings",
            fields: Object.values(fields.youtube),
          },
        ];
      case "facebook":
        return [
          {
            label: "Facebook Settings",
            fields: Object.values(fields.facebook),
          },
        ];
      case "twitter":
        return [
          {
            label: "X (Twitter) Settings",
            fields: Object.values(fields.twitter),
          },
        ];
      case "trovo":
        return [
          {
            label: "Trovo Settings",
            fields: Object.values(fields.trovo),
          },
        ];
    }
  });

  const form = useForm({
    validationSchema: computed(() => {
      const schemas: Record<string, any> = {
        destinationCount: z
          .number()
          .gt(0, { message: "At at least one destination is required" }),
      };

      for (const fieldset of fieldsets.value || []) {
        for (const field of fieldset.fields) {
          if (field.type === "checkbox_group") {
            for (const subfield of field.fields) {
              schemas[subfield.name] = unref(subfield.schema);
            }
            continue;
          }

          schemas[field.name] = unref(field.schema);
        }
      }

      return toTypedSchema(z.object(schemas));
    }),
  });

  watch(
    () => form.values.youtube_broadcast_id,
    (v) => {
      const stream = ytInfo.data?.scheduled_streams?.find(
        (stream) => stream.id === v,
      );

      if (stream) {
        form.setValues({
          youtube_title: stream.title,
          youtube_description: stream.description,
          youtube_category: stream.category_id,
          youtube_privacy: stream.privacy,
          youtube_thumbnail: stream.thumbnail.url,
          youtube_auto_start: stream.auto_start,
          youtube_auto_stop: stream.auto_stop,
          youtube_dvr: stream.dvr,
          youtube_360_video: stream.projection === "360",
          youtube_for_kids: stream.for_kids,
        });
      }
    },
  );

  return { fieldsets, fields, form };
};
