<template>
  <div class="-mr-4 w-full overflow-y-auto pr-4">
    <div class="flex justify-end">
      <DialogCreateDestination />
    </div>

    <UiFormField name="destinationCount">
      <UiFormItem>
        <UiFormLabel class="flex-grow text-lg font-medium">
          Destinations
        </UiFormLabel>
        <DestinationList class="w-full" />
        <UiFormMessage />
      </UiFormItem>
    </UiFormField>
  </div>
</template>
