<template>
  <UiPopover v-model:open="open">
    <!-- v-bind="{ id, ariaDescribedby, ariaInvalid }" -->
    <UiPopoverTrigger as-child>
      <UiButton
        variant="secondary"
        role="combobox"
        :aria-expanded="open"
        class="w-full justify-between px-3 text-[14px] font-normal"
        size="default"
      >
        {{ selectedOption?.label ?? placeholder }}

        <IconDropdown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </UiButton>
    </UiPopoverTrigger>
    <UiPopoverContent class="max-h-18 p-0" side="bottom" align="end">
      <UiCommand :filter-function="filterFunction">
        <UiCommandInput
          :placeholder="placeholder"
          @input="($event) => emit('input', $event)"
          @keydown.esc="
            () => {
              open = false;
            }
          "
        />
        <slot name="empty">
          <UiCommandEmpty>Nothing found.</UiCommandEmpty>
        </slot>

        <UiCommandList>
          <UiCommandGroup>
            <UiCommandItem
              v-for="option in sortedOptions"
              :key="option.value"
              :value="option"
              @select="
                () => {
                  value = option.value;
                  open = false;
                }
              "
            >
              <div
                class="flex items-center space-x-3"
                :class="{
                  'text-primary': value === option.value,
                }"
              >
                <Icon
                  size="1.25em"
                  name="lucide:check"
                  class="flex-shrink-0"
                  :class="value === option.value ? 'opacity-100' : 'opacity-0'"
                />
                <slot name="item" v-bind="{ option, value }">
                  <span>{{ option.label }}</span>
                </slot>
              </div>
            </UiCommandItem>
          </UiCommandGroup>
        </UiCommandList>
      </UiCommand>
    </UiPopoverContent>
  </UiPopover>
</template>

<script setup lang="ts">
import { useVModel } from "@vueuse/core";

type Value = string;
type Options = FieldSelectOptions;

const props = defineProps<{
  placeholder: string;
  options: Options;
  modelValue?: Value;

  id?: string;
  ariaDescribedby?: string;
  ariaInvalid?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: Value): void;
  (e: "input", event: InputEvent): void;
}>();
const value = useVModel(props, "modelValue", emit);
const open = ref(false);

// pin the selected item to the top
const sortedOptions = computed(() => {
  const options = props.options;

  const selectedIdx = options.findIndex(
    (option) => option.value === value.value,
  );

  const selectedOption =
    selectedIdx >= 0 ? options.splice(selectedIdx, 1)[0] : undefined;

  //   options.sort();

  if (selectedOption) {
    options.unshift(selectedOption);
  }

  return options;
});

const selectedOption = computed(() =>
  sortedOptions.value.find((option) => option.value === value.value),
);

const filterFunction = (list: any, searchTerm: string) => {
  return (list as FieldSelectOptions).filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );
};
</script>
